@tailwind base;
@tailwind components;
@tailwind utilities;

.app-container {
  max-width: 1280px;
  margin: 0 auto;
}

.header {
  background: linear-gradient(
    180deg,
    #000,
    rgba(0, 0, 0, 0.65) 50%,
    transparent
  );
}

.gradient-1 {
  background: linear-gradient(180deg, #181818, transparent);
}

.white-glow {
  box-shadow: 0 0 15px #fff;
}

.tv-bg {
  background-image: url("./assets/icons/apple-tv-background.jpg");
}

.starry-night {
  background-image: url("./assets/icons/background.svg");
}

input:checked:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #fff;
}

footer ul li {
  margin-top: 12px;
  font-size: 16px;
  font-family: "Barlow";
}
@font-face {
  font-family: "Barlow";
  src: url("https://fonts.googleapis.com/css?family=Barlow|Saira+Extra+Condensed:600");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("https://fonts.googleapis.com/css2?family=Teko:wght@600&display=swap");
  font-weight: 400;
  font-style: normal;
}